export default {
    init() {
        $('.hamburger').on('click', function (e) {
            e.preventDefault();
            if(!$('.mobile-navigation').hasClass('open')) {
                $('.overlay-bg').addClass('is-active');
                $('.mobile-navigation').addClass('open');
            } else {
                $('.overlay-bg').removeClass('is-active');
                $('.mobile-navigation').removeClass('open');
            }
        });

        $('.overlay-bg').on('click', function (e) {
            e.preventDefault();
            if($('.mobile-navigation').hasClass('open')) {
                $(this).removeClass('is-active');
                $('.mobile-navigation').removeClass('open');
            }
        });

        $('.wpml-ls-current-language').children('.nav-link').on('click', function (e) {
           e.preventDefault();
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
